import {PagesComponent} from "./pages/pages.component";
import {Route, RouterModule, Routes} from "@angular/router";
import {ModuleWithProviders} from "@angular/core";
import {AuthGuard} from "./guards/auth.guard";


export const routes: Routes = [
  {
    path: 'Login',
    loadChildren: () => import('./pages/login-container/login-container.module').then(m => m.LoginContainerModule)},
  {
    path: '',
    component: PagesComponent,
    children: [
      {path: 'Home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule ),  canActivate: [AuthGuard]},
      {path: 'Business-partner', loadChildren: () => import('./pages/business-partner/business-partner.module').then(m => m.BusinessPartnerModule ), canActivate: [AuthGuard]},
      {path: 'Document', loadChildren: () => import('./pages/document/document.module').then(m => m.DocumentModule ), canActivate: [AuthGuard]},
      {path: 'Notes', loadChildren: () => import('./pages/notes-log/notes-log.module').then(m => m.NotesLogModule ), canActivate: [AuthGuard]},

      {
        path: '',
        redirectTo: '/Login',
        pathMatch: 'full'
      },
    ]
  },
];

export const AppRoutingModule: ModuleWithProviders<Route> = RouterModule.forRoot(routes, {
  useHash: true,
  onSameUrlNavigation: 'reload'
});
